import "../styles/styles.css"
import "../styles/tailwind.css"
import "aos/dist/aos.css"

import React, { useEffect } from "react"

import Aos from "aos"
import PropTypes from "prop-types"

import {
  Footer,
  Partners
} from "../pages/index"
import { Header } from "./header2"
import { SendUsRequest } from "./send_us_request"
import { TouchCoreInsight } from "./tc_insight"

const Layout = ({ children, tcoreinsight = true, insightCategory = "" }) => {
  useEffect(() => {
    console.log("initializing AOS")
    Aos.init({
      duration: 1200,
      offset: 120,
      delay: 270,
    })
  }, [])
  return (
    <>
      <main className="max-w-full overflow-x-hidden">
        <Header type={2} />
        <div className="mt-16 flex-1">
          <svg width="0" height="0">
            <defs>
              <clipPath id="myCurve2" clipPathUnits="objectBoundingBox">
                <path
                  d="M 0,1
                    L 0,0
                    L 1,0
                    L 1,.85
                    C .65 .8, .85 .8, 0 1
                    Z"
                />
              </clipPath>
            </defs>
          </svg>
          <main>{children}</main>

          {tcoreinsight && <TouchCoreInsight category={insightCategory} />}
          <SendUsRequest />
          <Partners />
          <Footer />
        </div>
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
