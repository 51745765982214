import React from "react"

import { Link } from "gatsby"

import { usePost } from "../utils/insights"

export const TouchCoreInsight = ({ category }) => {
  const post = usePost({
    type: "latest-category",
    categorySlug: category,
    limit: 3,
  })
  return (
    <section className=" mt-20">
      <h5
        data-aos="fade-left"
        className="text-3xl md:text-4xl text-center  pl-3 md:pl-0  font-black text-pgray"
      >
        Touchcore's Insight
      </h5>
      {!post.loading && !post.posts.length && (
        <div className="text-center py-16 w-full">No Insights found</div>
      )}
      <div className="grid gap-8 grid-cols-1 md:grid-cols-3 px-4 md:px-10 lg:px-20 my-16">
        {post.posts.map((p, i) => (
          <div
            data-aos="fade-up"
            data-aos-delay={400 * 100 + i}
            key={"tci-" + i}
            className="flex flex-col shadow-lg hover:shadow-md text-pgray"
          >
            <Link className="block" to={`/our-insights?post=${p.id}`}>
              <img
                src={post.image(p)}
                className="object-cover bg-gray-100 w-full inline-block h-40"
                alt="blog"
              />
              <div className="p-4">
                <span className="capitalized-heading">
                  {p._embedded["wp:term"][0].map(v => v.name).join(", ")}
                </span>
                <h6 className="font-semibold text-sm">{p.title.rendered}</h6>
                <p className=" inline-block my-3 h-10 overflow-hidden">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: p.excerpt.rendered,
                    }}
                  ></span>
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}
