import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { WhyChooseUs } from "./what-we-do"

const HeroSection = () => (
  <section className="relative flex items-end md:items-center h-screen-fit md:h-screen overflow-hidden">
    <img
      alt=""
      className="hidden md:block absolute w-auto md:w-full h-full md:h-auto  top-0 object-cover md:object-contain z-0"
      src={require("../assets/linkedin-sales-naviga.png")}
    />
    <img
      alt=""
      className="md:hidden absolute w-full h-full md:h-auto  top-0 object-cover z-0"
      src={require("../assets/Learn more about touchcore.jpg")}
    />
    <div
      data-aos="slide-left"
      className="container pb-32 text-white md:text-black  text-center md:text-left  md:py-0 px-5 md:px-12 relative z-20 "
    >
      <h1 className="text-4xl md:text-5xl font-black leading-tight">
        Little Stone. <br /> <span>Big Splash</span>
      </h1>
      <p className="text-base mt-6">
        That's the simplest way to
        <br className="md:hidden" /> describe Touchcore
      </p>
    </div>
  </section>
)
const Activity = () => {
  const data = [
    {
      title: (
        <>
          From inception,
          <br className="md:hidden" />
          To delivery
        </>
      ),
      className: "hidden md:block",
      content: (
        <>
          <p>
            From start to finish, we have the right team to deliver. Everything
            is built under one roof. This means less risk and a cohesive team to
            ensure forward momentum.
          </p>
          <p>
            From product strategy to product design to full stack engineering,
            Touchcore handles every aspect of digital product development so
            your insight and intelligence result in ample reward.
          </p>
        </>
      ),
      image: require("./../assets/tracy-adams-TEemXOpR3cQ-unsplash@2x.png"),
      image_position: "left",
    },
    {
      title: "Future perfect",
      content: (
        <p>
          You’re investing more than money — it’s also energy, intelligence,
          brand, and the expanding potential of your business. Touchcore
          translates this investment into meaningful impact for you and your
          audience.
        </p>
      ),
      image: require("./../assets/desola-lanre-ologun-9-B_-_e6uUk-unsplash.png"),
      image_position: "right",
    },
    {
      title: "Full-service, full-stack",
      content: (
        <>
          <p>
            From start to finish, we have the right team to deliver. Everything
            is built under one roof. This means less risk and a cohesive team to
            ensure forward momentum.
          </p>
          <p>
            From product strategy to product design to full stack engineering,
            Touchcore handles every aspect of digital product development so
            your insight and intelligence result in ample reward.
          </p>
        </>
      ),
      image: require("./../assets/tracy-adams-TEemXOpR3cQ-unsplash@2x.png"),
      image_position: "left",
    },
    {
      title: "Clean Crisp User Interface & Experience",
      content:
        "We code apps that get to the heart of your business objectives. With great attention to quality, we build software, mobile, and web apps that integrate seamlessly into your infrastructure and delight your users.",
      image: require("./../assets/tcore-banner.svg"),
      image_position: "right",
    },
    {
      image: require("./../assets/markus-spiske-1LLh8k2_YFk-unsplash (1).png"),
      title: "Agile process that delivers results",
      content:
        "Using Agile processes, Touchcore professionals unite to function as a synchronized unit with close client collaboration, so the learning flows in both directions. Touchcore User Experience Developers (UXDs) are the keystone in our distinctive process — allowing us to close the gap between design and engineering in ways our competitors can’t.",
      image_position: "left",
    },
  ]
  return (
    <div className="container mx-auto mt-0 mb-0 md:my-20 px-5 md:px-10 items-center">
      {data.map((v, i) => {
        const image = (
          <img
            data-aos={
              v.image_position === "left" ? "slide-right" : "slide-left"
            }
            data-aos-delay={240 + 50 * i}
            className={"w-full md:w-1/2 md:p-10 " + v.className}
            alt=""
            src={v.image}
          />
        )
        return (
          <div
            className={
              (v.image_position === "right"
                ? "md:flex-row-reverse"
                : "md:flex-row") + " flex-col flex items-center"
            }
          >
            {image}
            <div
              data-aos={
                v.image_position === "left" ? "slide-left" : "slide-right"
              }
              data-aos-delay={240 + 50 * i}
              className="py-10 md:p-10"
            >
              <h6 className="text-2xl font-black text-pgray text-center md:text-left">
                {v.title}
              </h6>
              <div className="text-pgray2 mt-5 text-justify md:text-left text-sm">
                {v.content}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
const WhyTouchCore = () => (
  <Layout>
    <SEO title="Why Touchcore" />
    <HeroSection />
    <Activity />
    <WhyChooseUs />
  </Layout>
)

export default WhyTouchCore
